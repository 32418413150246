<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><line fill="none" stroke-miterlimit="10" x1="32" y1="60" x2="32" y2="28" stroke-linecap="butt" :stroke="fill" /> <polyline fill="none" stroke-miterlimit="10" points="22,38 32,28 42,38 " :stroke="fill" /> <path fill="none" stroke-miterlimit="10" d="M40,46h12 c5.6-0.5,10-5.3,10-11c0-5.8-4.4-10.5-10.1-11C50.9,13.9,42.4,6,32,6c-9,0-16.6,6-19.1,14.2C6.7,21.2,2,26.5,2,33 c0,6.8,5.3,12.5,12,13h10" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>