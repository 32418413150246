<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polyline fill="none" stroke-miterlimit="10" points="20,1 20,9 28,9 " stroke-linecap="butt" :stroke="fill" /> <polyline fill="none" stroke-miterlimit="10" points="28,13 28,9 20,1 4,1 4,13 " :stroke="fill" /> <polyline fill="none" stroke-miterlimit="10" points="4,27 4,31 28,31 28,27 " :stroke="fill" /> <path d="M9.3,24.8c-0.3,0-0.5,0-0.8-0.1v-1.2c0.1,0,0.2,0,0.3,0.1s0.2,0,0.3,0 c0.3,0,0.4-0.1,0.5-0.2s0.2-0.4,0.2-0.8v-5.3h1.6v5.2c0,0.8-0.2,1.4-0.5,1.8S9.9,24.8,9.3,24.8z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <path d="M16.7,19.1c0,0.6-0.2,1.1-0.6,1.5s-0.9,0.5-1.6,0.5h-0.4V23h-1.5v-5.7h2 c0.7,0,1.3,0.2,1.6,0.5S16.7,18.5,16.7,19.1z M14.1,19.8h0.3c0.2,0,0.4-0.1,0.6-0.2s0.2-0.3,0.2-0.5c0-0.4-0.2-0.6-0.6-0.6h-0.4 V19.8z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <path d="M19.9,19.7h2.5v3.1c-0.7,0.2-1.4,0.3-2.2,0.3c-0.9,0-1.6-0.3-2-0.8 s-0.7-1.2-0.7-2.2c0-0.9,0.3-1.6,0.8-2.2s1.3-0.8,2.2-0.8c0.4,0,0.7,0,1,0.1s0.6,0.2,0.8,0.3l-0.5,1.2c-0.4-0.2-0.9-0.3-1.4-0.3 c-0.5,0-0.8,0.1-1,0.4S19,19.6,19,20.2c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,1,0.4c0.2,0,0.4,0,0.6-0.1v-0.9h-1V19.7z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <rect x="1" y="13" fill="none" stroke-miterlimit="10" width="30" height="14" :stroke="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>