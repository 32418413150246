<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polyline fill="none" stroke-miterlimit="10" points="20,1 20,9 28,9 " stroke-linecap="butt" :stroke="fill" /> <polyline fill="none" stroke-miterlimit="10" points="28,13 28,9 20,1 4,1 4,13 " :stroke="fill" /> <polyline fill="none" stroke-miterlimit="10" points="4,27 4,31 28,31 28,27 " :stroke="fill" /> <path d="M13.143,19.105c0,0.638-0.188,1.131-0.564,1.479s-0.911,0.521-1.604,0.521h-0.434 V23H8.998v-5.711h1.977c0.721,0,1.263,0.157,1.625,0.473S13.143,18.524,13.143,19.105z M10.541,19.848h0.281 c0.232,0,0.416-0.065,0.553-0.195s0.205-0.31,0.205-0.539c0-0.386-0.213-0.578-0.641-0.578h-0.398V19.848z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <path d="M18.9,20.023c0,0.953-0.262,1.688-0.787,2.203S16.851,23,15.9,23h-1.848v-5.711 h1.977c0.917,0,1.625,0.234,2.123,0.703S18.9,19.138,18.9,20.023z M17.299,20.078c0-0.523-0.104-0.911-0.311-1.164 s-0.521-0.379-0.943-0.379h-0.449v3.199h0.344c0.469,0,0.812-0.136,1.031-0.408S17.299,20.638,17.299,20.078z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <path d="M21.443,23h-1.52v-5.711h3.375v1.238h-1.855v1.09h1.711v1.238h-1.711V23z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <rect x="1" y="13" fill="none" stroke-miterlimit="10" width="30" height="14" :stroke="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>