import Bus from '../inc/bus';
import Backend from '../inc/backend';
import ObjectStore from '../inc/objectStore';
import Store from '../inc/store';
import {updateProgress} from '../inc/courseUtils';

const STATUS_FAILED = 3,
	STATUS_INITIAL = 0,
	STATUS_SAVING = 1,
	STATUS_SUCCESS = 2;

export default {
	data() {
		return {
			uploadError: null,
			currentStatus: null,
			fileUrls: [],
			uploadedFiles: [],
			answer: null
		};
	},
	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
		},
		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		},
		fileLimit() {
			return this.block.meta.maxFiles || 1;
		},
		allowedFileTypes() {
			return ['*'];
		},
		answers() {
			return Store.groupAnswers;
		}
	},
	created() {
		this.answer = this.getAnswer();
	},
	watch: {
		answers() {
			this.answer = this.getAnswer();
		}
	},
	methods: {
		getAnswer() {
			const groupAnswers = this.answers.find(ca => ca.blockId === this.block.id);

			return groupAnswers && groupAnswers.answers ? groupAnswers.answers : null;
		},
		reset() {
			this.currentStatus = STATUS_INITIAL;
			this.uploadError = null;
		},
		upload(file) {
			return ObjectStore.upload(file).then(uploaded => this.uploadedFiles.push(uploaded));
		},
		filesChange(fileList) {
			if(!fileList.length) {
				return;
			}

			if(Store.session.timeEnded) {
				Bus.emit('error', 'This course has been completed, no further updates allowed.');

				return;
			}

			if(Store.courseGroupProgress.timeGraded) {
				Bus.emit('error', 'This unit has been graded, no further updates allowed.');

				return;
			}

			if(this.answer && this.answer.files && (fileList.length + this.answer.files.length) > this.fileLimit) {
				const remainingFiles = this.fileLimit - this.answer.files.length;

				if(remainingFiles > 0) {
					Bus.emit('error', `You can only upload ${remainingFiles} more files.`);
				}
				else {
					Bus.emit('error', 'You can\'t upload any more files.');
				}

				return;
			}

			this.currentStatus = STATUS_SAVING;
			const promises = [];

			for(let i = 0; i < fileList.length; i++) {
				if(this.allowedFileTypes.includes('*') || this.allowedFileTypes.includes(fileList[i].type)) {
					promises.push(this.upload(fileList[i]));
				}
				else {
					Bus.emit('error', `File type not allowed: ${fileList[i].type}. Skipping...`);
				}
			}

			Promise.all(promises).then(() => {
				this.currentStatus = STATUS_SUCCESS;

				const body = {
					block: this.block.id,
					group: Store.courseGroup.id,
					answer: {
						files: this.uploadedFiles
					}
				};

				Backend.post(`sessions/${Store.session.id}/answers`, body)
					.then(() => updateProgress(Store.courseGroup.id, true))
					.then(() => Bus.emit('success', 'Files uploaded successfully!'))
					.catch(() => Bus.emit('error', 'Failed to upload file(s).'));
			}).catch(err => {
				this.uploadError = err.response;
				this.currentStatus = STATUS_FAILED;
			});
		}
	}
};