<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polyline fill="none" stroke-miterlimit="10" points="21,1 21,9 29,9 " stroke-linecap="butt" :stroke="fill" /> <polygon fill="none" stroke-miterlimit="10" points="21,1 3,1 3,31 29,31 29,9 " :stroke="fill" /> <line fill="none" stroke-miterlimit="10" x1="9" y1="22" x2="23" y2="22" /> <line fill="none" stroke-miterlimit="10" x1="9" y1="16" x2="23" y2="16" /> <line fill="none" stroke-miterlimit="10" x1="9" y1="10" x2="15" y2="10" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>