<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polyline fill="none" stroke-miterlimit="10" points="20,1 20,9 28,9 " stroke-linecap="butt" :stroke="fill" /> <polyline fill="none" stroke-miterlimit="10" points="28,13 28,9 20,1 4,1 4,13 " :stroke="fill" /> <polyline fill="none" stroke-miterlimit="10" points="4,27 4,31 28,31 28,27 " :stroke="fill" /> <path d="M11.9,19.1c0,0.6-0.2,1.1-0.6,1.5s-0.9,0.5-1.6,0.5H9.3V23H7.8v-5.7h2 c0.7,0,1.3,0.2,1.6,0.5S11.9,18.5,11.9,19.1z M9.3,19.8h0.3c0.2,0,0.4-0.1,0.6-0.2s0.2-0.3,0.2-0.5c0-0.4-0.2-0.6-0.6-0.6H9.3V19.8z " stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <path d="M18.3,23h-2l-2.1-4h0c0,0.6,0.1,1.1,0.1,1.4V23h-1.4v-5.7h2l2.1,4h0 c0-0.6-0.1-1-0.1-1.4v-2.6h1.4V23z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <path d="M21.8,19.7h2.5v3.1c-0.7,0.2-1.4,0.3-2.2,0.3c-0.9,0-1.6-0.3-2-0.8 s-0.7-1.2-0.7-2.2c0-0.9,0.3-1.6,0.8-2.2s1.3-0.8,2.2-0.8c0.4,0,0.7,0,1,0.1s0.6,0.2,0.8,0.3l-0.5,1.2c-0.4-0.2-0.9-0.3-1.4-0.3 c-0.5,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.3c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,1,0.4c0.2,0,0.4,0,0.6-0.1v-0.9h-1V19.7z" stroke-linejoin="miter" stroke-linecap="square" stroke="none" /> <rect x="1" y="13" fill="none" stroke-miterlimit="10" width="30" height="14" :stroke="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>