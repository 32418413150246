<template>
	<div class="block-inner">
		<header v-html="content"></header>

		<div v-if="isFailed">
			<h2>Upload failed.</h2>
			<pre>{{ uploadError }}</pre>
		</div>

		<DropZone v-else :max-files="block.meta.maxFiles" :is-saving="isSaving" :uploaded-files="uploadedFiles" :accept-types="block.meta.allowedFileTypes" @upload="filesChange" />

		<BaseButton v-if="uploadedFiles.length" type="secondary" class="clear-files" @click="removeFiles">
			<IconCross :width="14" :height="14" />
			<span>Remove files</span>
		</BaseButton>
	</div>
</template>

<script>
	import DropZone from '../DropZone';
	import uploadMixin from '../../mixins/uploadMixin';
	import BaseButton from '../BaseButton';
	import IconCross from '../icons/IconCross';
	import Store from '../../inc/store';
	import Backend from '../../inc/backend';
	import {jsonToHtml} from '../../inc/text';
	import Bus from '../../inc/bus';

	export default {
		components: {
			DropZone,
			BaseButton,
			IconCross
		},
		mixins: [uploadMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		computed: {
			content() {
				return jsonToHtml(this.block.content);
			}
		},
		watch: {
			answer() {
				this.uploadedFiles = this.answer && this.answer.files ? this.answer.files : [];
			}
		},
		methods: {
			removeFiles() {
				if(Store.courseGroupProgress.timeGraded) {
					Bus.emit('error', 'This unit has been graded, no further updates allowed.');

					return;
				}

				Backend.delete(`sessions/${Store.session.id}/answers/${this.block.id}`).then(() => {
					this.answer = null;
					this.uploadedFiles = [];
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.clear-files::v-deep {
		button {
			padding: 0;
		}

		span {
			font-weight: $font__normal;
		}
	}
</style>