<template>
	<div :class="classes">
		<form enctype="multipart/form-data" novalidate>
			<div class="file-upload">
				<input
					type="file"
					multiple
					name="files"
					:disabled="isSaving"
					:accept="allowedFileTypes.join(',')"
					class="input-file"
					@change="$emit('upload', $event.target.files)"
				/>
				<p v-if="isSaving">
					Uploading files...
				</p>
				<div v-else-if="uploadedFiles.length" class="uploaded-files">
					<strong v-if="!compact">Uploaded files</strong>
					<div v-for="file in uploadedFiles" :key="file.file">
						<IconFilePdf v-if="file.type === 'application/pdf'" :width="32" :height="32" />
						<IconFileJpg v-else-if="file.type === 'image/jpeg'" :width="32" :height="32" />
						<IconFilePng v-else-if="file.type === 'image/png'" :width="32" :height="32" />
						<IconFileDefault v-else :width="32" :height="32" />
						<small>{{ file.name }}</small>
					</div>
				</div>
				<div v-else>
					<IconUpload v-if="compact" :width="32" :height="32" />
					<IconUpload v-else :width="64" :height="64" />
					<div>
						Drag your files here to upload<br /> or click to browse
					</div>
				</div>
				<span v-if="!compact">Allowed filetypes: {{ allowedFileTypeLabels.join(', ') }}</span>
				<span v-if="!compact">Max nr of files: {{ maxFiles }}</span>
			</div>
		</form>
	</div>
</template>

<script>
	import IconUpload from './icons/IconUpload';
	import IconFilePdf from './icons/IconFilePdf';
	import IconFileJpg from './icons/IconFileJpg';
	import IconFilePng from './icons/IconFilePng';
	import IconFileDefault from './icons/IconFileDefault';

	export default {
		components: {
			IconUpload,
			IconFilePdf,
			IconFileJpg,
			IconFilePng,
			IconFileDefault
		},
		props: {
			maxFiles: {
				type: Number,
				default: 1
			},
			uploadedFiles: {
				type: Array,
				default: () => []
			},
			isSaving: {
				type: Boolean,
				default: false
			},
			acceptTypes: {
				type: Array,
				default: () => []
			},
			compact: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			allowedFileTypes() {
				if(!this.acceptTypes.length) {
					return ['*'];
				}

				let types = [];

				this.acceptTypes.forEach(type => {
					types = types.concat(type.mimeTypes);
				});

				return types.filter((value, index, self) => self.indexOf(value) === index);
			},
			allowedFileTypeLabels() {
				if(!this.acceptTypes.length) {
					return ['All'];
				}

				const labels = this.acceptTypes.map(type => type.label);

				return labels.filter((value, index, self) => self.indexOf(value) === index);
			},
			classes() {
				return 'dropzone' + (this.compact ? ' compact' : '');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.dropzone {
		min-height: 200px;

		form {
			height: 100%;
		}

		&.compact {
			min-height: 0;

			.file-upload > div {
				margin-bottom: 0;
				font-size: 0.8em;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				text-align: left;

				& > svg {
					margin-right: 10px;
					margin-bottom: 0;
				}
			}
		}
	}

	.file-upload {
		background: $color__white;
		padding: 10px 10px;
		height: 100%;
		position: relative;
		cursor: pointer;
		border-radius: $border_radius;
		box-shadow: $box_shadow__input;
		transition: 0.2s ease;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;

		svg {
			display: block;
			margin: 0 auto 0.25rem;
		}

		& > p,
		& > div {
			font-size: 1.2em;
			text-align: center;
			padding: 0;
			margin: 0 0 $default_padding / 2;
		}

		&:hover {
			box-shadow: $box_shadow;
		}

		.input-file {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}

	.uploaded-files {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		& > :first-child {
			flex: 1 0 100%;
			margin-bottom: $default_padding / 2;
		}

		& > div {
			padding: $default_padding / 2;
			flex: 1 0 200px;
			max-width: 200px;
		}

		small {
			display: block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		svg {
			display: block;
			margin: 0 auto $default_padding / 2;
		}
	}
</style>